import { useState, useEffect } from "react";

export const UsesCounter = ({inputData}) => {
    const [totalUses, setTotalUses] = useState(0);
    useEffect(() => {
        setTotalUses(inputData);
    }, [inputData]);
  return <main>
    <div className="card h-100">
        <div className="card-body pt-0">            
            <div className="row">
                <div
                style={{ fontSize: "3.5rem", fontWeight: "bold" }}
                className="py-4"
                >
                    <strong>
                        {totalUses}
                    </strong>
                </div>
            </div>
            <h6 className="card-title text-muted mt-3 mb-0 customBoldSoft"><strong>DAILY TOTAL USES</strong></h6>
        </div>
    </div>
  </main>;
}