import { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './checkbox.css';
import './App.css';
import { Auth } from './components/auth';
import MqttComponent from './components/mqttComponent';
import { HandleIssue } from './components/handleIssue';
import { IssuesTable } from './components/issuesTable';
import { UnitActivityChart } from './components/unitActivityChart';
import { PieChart } from './components/pieChart';
import { UsesCounter } from './components/usesCounter';
import { DailyUptimeCounter } from './components/dailyUptimeCounter';
import { auth, db, database } from './firebase';
import { ref, set, onValue, off, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import {
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  query,
  where,
} from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as KamleonLogo } from './img/kamleon_logo.svg';
import { ReactComponent as LogOut } from './img/logout.svg';
import { ReactComponent as GearIcon } from './img/gear.svg';
import { ReactComponent as Critical } from './img/critical.svg';
import { ReactComponent as Error } from './img/error.svg';
import { ReactComponent as Warning } from './img/warning.svg';
import { ReactComponent as Exception } from './img/exception.svg';

function App() {
  const [user, setUser] = useState(() => JSON.parse(sessionStorage.getItem('user')) || null);
  var userName = sessionStorage.getItem('userName');
  var userLastName = sessionStorage.getItem('userLastName');
  var userRole = sessionStorage.getItem('userRole');
  const [email, setEmail] = useState(sessionStorage.getItem('userEmail') || '');
  const [dashboardUnitsComplete, setDashboardUnitsComplete] = useState({});
  const [dashboardUnitsUpdated, setDashboardUnitsUpdated] = useState({});
  const [groupedDataByUnit, setGroupedDataByUnit] = useState({});
  const [data, setData] = useState([]);
  const [criticalIssuesData, setCriticalIssuesData] = useState(new Set());
  const [usersData, setUsersData] = useState([]);
  const [unitsFirestoreData, setUnitsFirestoreData] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [dashboardUnitsData, setDashboardUnitsData] = useState([]);
  const [groupIssues, setGroupIssues] = useState(true);
  const [issueGroupToModify, setIssueGroupToModify] = useState({});
  const [issueIdFilter, setIssueIdFilter] = useState('');
  const [issueTypeInDashboardFilter, setIssueTypeInDashboardFilter] = useState('');
  const [issueTypeFilter, setIssueTypeFilter] = useState('');
  const [centerFilter, setCenterFilter] = useState('');
  const [organizationFilter, setOrganizationFilter] = useState('');
  const [unitIdFilter, setUnitIdFilter] = useState('');
  const [unitChart, setUnitChart] = useState([
    { type: 'Active', amount: 0, color: '#2FD5CB' },
    { type: 'Disabled', amount: 0, color: '#FC664E' },
  ]);
  const [pieChartData, setPieChartData] = useState([
    { type: 'Critical', amount: 0, color: '#000000' },
    { type: 'Error', amount: 0, color: '#FC664E' },
    { type: 'Warning', amount: 0, color: '#FAC63E' },
    { type: 'Exception', amount: 0, color: '#808285' },
  ]);
  const [barChartData, setBarChartData] = useState([
    { type: 'Cartridge', amount: 0, color: '#2C2C2C' },
    { type: 'Screen', amount: 0, color: '#757575' },
    { type: 'Unit', amount: 0, color: '#b8b8b8' },
  ]);

  const [totalDailyUptime, setTotalDailyUptime] = useState(0);
  const [showApp, setShowApp] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showIssueSettings, setShowIssueSettings] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  const [showIssuesTable, setShowIssuesTable] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedUnitItem, setSelectedUnitItem] = useState(null);
  const [selectedUnitNotes, setSelectedUnitNotes] = useState(null);
  const [selectedUnitCenter, setSelectedUnitCenter] = useState(null);
  const [currentIssuesList, setCurrentIssuesList] = useState([]);
  const [showMqttComponent, setShowMqttComponent] = useState(false);
  const [showMqttModal, setShowMqttModal] = useState(false);
  const [showHandleIssue, setShowHandleIssue] = useState(false);
  const [issueToModify, setIssueToModify] = useState(null);
  const [sortField, setSortField] = useState('unit_status');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortFieldIssue, setSortFieldIssue] = useState('timestamps.open.Last_open');
  const [sortOrderIssue, setSortOrderIssue] = useState('desc');
  const [issueIDs, setIssueIDs] = useState([{ value: '', label: 'Any issue hidden' }]);
  const [issueIDsSelected, setIssueIDsSelected] = useState([]);
  const [totalDailyUses, setTotalDailyUses] = useState(0);
  const [issueStatusSelected, setIssueStatusSelected] = useState([
    { value: "Open", label: "Open" },
    { value: "In progress", label: "In progress" },
  ]);

  const issueStatus = [
    { value: "Open", label: "Open" },
    { value: "In progress", label: "In progress" },
    { value: "Closed", label: "Closed" },
  ];

  const critical_issues = collection(db, 'critical_issues');
  const devices = collection(db, 'devices');
  const units = collection(db, 'units');
  const monitoring_dashboard = collection(db, 'monitoring_dashboard');
  const kam_eye_issues = collection(db, 'kam_eye');
  const users = collection(db, 'users');

  // Ref to track initial load
  const isInitialLoadIssues = useRef(true);
  const isInitialLoadUnits = useRef(true);
  const isInitialLoadDevices = useRef(true);
  const criticalIssuesDataRef = useRef(criticalIssuesData);
  const dashboardUnitsDataRef = useRef(dashboardUnitsData);

  const navigate = useNavigate();

  const updateUnitChartData = (filteredData) => {
    const activeUnits = filteredData.filter(item => item.status === 'active').length;
    const disabledUnits = filteredData.filter(item => item.status === 'disabled').length;

    setUnitChart([
      { type: 'Active', amount: activeUnits, color: '#2FD5CB' },
      { type: 'Disabled', amount: disabledUnits, color: '#FC664E' },
    ]);
  };



  const updatePieChartData = (filteredData) => {
    const issueTypeCounts = {};
    filteredData
      .filter(item => item.Issue.status === 'Open' || item.Issue.status === 'In progress')
      .forEach(item => {
        const issueType = item.Issue.type;
        issueTypeCounts[issueType] = (issueTypeCounts[issueType] || 0) + 1;
      });
    const newPieChartData = pieChartData.map(data => ({
      ...data,
      amount: issueTypeCounts[data.type] || 0,
    }));

    setPieChartData(newPieChartData);
  };

  const updateBarChartData = (filteredData) => {
    const deviceTypeCounts = {};
    filteredData
      .filter(item => item.Issue.status === 'Open' || item.Issue.status === 'In progress')
      .forEach(item => {
        const deviceType = item.Installation.Dev_type;
        deviceTypeCounts[deviceType] = (deviceTypeCounts[deviceType] || 0) + 1;
      });
    const newBarChartData = barChartData.map(data => ({
      ...data,
      amount: deviceTypeCounts[data.type] || 0,
    }));

    setBarChartData(newBarChartData);
  };

  // const updateStateCounterData = (filteredData) => {
  //   const stateCounts = {};
  //   filteredData.forEach(item => {
  //     const state = item.Issue.status;
  //     stateCounts[state] = (stateCounts[state] || 0) + 1;
  //   });
  //   const newStateCounterData = stateCounteData.map(data => ({
  //     ...data,
  //     amount: stateCounts[data.type] || 0,
  //   }));

  //   setStateCounterData(newStateCounterData);
  // };

  const handleSort = (field) => {
    // If clicking on the same field, reverse the sort order
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSortIssue = (field) => {
    // If clicking on the same field, reverse the sort order
    if (field === sortFieldIssue) {
      setSortOrderIssue(sortOrderIssue === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortFieldIssue(field);
      setSortOrderIssue('asc');
    }
  };

  const fetchData = async () => {
    try {
      const criticalIssuesPromise = getDocs(critical_issues);
      // const kamEyeIssuesPromise = getDocs(
      //   query(kam_eye_issues, where("Issue.status", "in", ["Open", "In progress"]))
      // );
      const kamEyePath = '/kam_eye';  // The path to your data in the Realtime Database
      const kamEyeSnapshotRef = ref(database, kamEyePath);
      const kamEyeIssuesPromise2 = new Promise((resolve, reject) => {
        onValue(kamEyeSnapshotRef, (snapshot) => {
          const data = snapshot.val();
          resolve(data ? Object.entries(data).map(([id, value]) => ({ ...value, id })) : []);
        }, (error) => {
          reject(error);
        });
      });
      const unitsPath = '/units';
      const unitsSnapshotRef = ref(database, unitsPath);
      const unitsPromise2 = new Promise((resolve, reject) => {
        onValue(unitsSnapshotRef, (snapshot) => {
          const data = snapshot.val();
          resolve(data ? Object.entries(data).map(([id, value]) => ({ ...value, id })) : []);
        }, (error) => {
          reject(error);
        });
      });
      const unitsFirestorePromise = getDocs(units);
      const devicesPromise = getDocs(devices);
      const dashboardPromise = getDocs(monitoring_dashboard);

      const [
        criticalIssuesSnapshot,
        kamEyeIssuesSnapshot2,
        devicesSnapshot,
        dashboardSnapshot,
        unitsSnapshot2,
        unitsFirestoreSnapshot,
      ] = await Promise.all([
        criticalIssuesPromise,
        kamEyeIssuesPromise2,
        devicesPromise,
        dashboardPromise,
        unitsPromise2,
        unitsFirestorePromise
      ]);

      // Step 1: Process critical_issues collection data
      const criticalIssueIds = new Set(criticalIssuesSnapshot.docs.map(doc => doc.data().issueId.toLowerCase()));
      setCriticalIssuesData(criticalIssueIds);

      // Step 2: Process kam_eye_issues collection data
      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);


      const filteredData = kamEyeIssuesSnapshot2.map((data) => {
        const issueId = data.Issue?.ID?.toLowerCase();

        if (issueId && criticalIssueIds.has(issueId)) {
          data.Issue.type = "Critical";
        }

        return data;
      });

      // Step 3: Update the state and charts
      setData(filteredData);
      updatePieChartData(filteredData);
      updateBarChartData(filteredData);

      const devicesData = devicesSnapshot.docs.map(doc => doc.data());
      setDevicesData(devicesData);

      const unitsFirestoreData = unitsFirestoreSnapshot.docs.map(doc => doc.data());
      setUnitsFirestoreData(unitsFirestoreData);

      const dashboardUnitsData = dashboardSnapshot.docs.map(doc => doc.data());
      
      const unitsData = unitsSnapshot2;
      setUnitsData(unitsData);
      
      const dashboardUnitIds = dashboardUnitsData.map(unit => unit.unitId);
      
      const nonMatchingUnits = unitsData.filter(unit => !dashboardUnitIds.includes(unit.unitID));

      const nonMatchingUnitIds = [
        ...nonMatchingUnits.map(unit => ({
          unitId: unit.unitID
        })),
        { unitId: 'backend' }
      ];
      
      setDashboardUnitsData(nonMatchingUnitIds);
      

      updateUnitChartData(nonMatchingUnits);

      const data = await updateDashboardUnits('all', filteredData, devicesData, nonMatchingUnitIds, unitsData, unitsFirestoreData);
      const updatedData = filterAndSortDashboardUnits(data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect( () => {
    try {
      if (selectedUnit !== null) {
        handleCurrentIssuesList(selectedUnit);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [data]);

  const fetchUsers = async () => {
    try {
      const userDocs = await getDocs(users);
      const usersArray = userDocs.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((user) => user.kamleonPermissions?.userRole === 'kamleon_admin' || user.kamleonPermissions?.userRole === 'kamleon_viewer' || user.kamleonPermissions?.userRole === 'kamleon_editor' || user.kamleonPermissions?.userRole === 'kamleon_owner');

      setUsersData(usersArray);
    } catch (error) {
      console.error('Error fetching users data:', error);
    }
  };

  function addLeadingZero(number) {
    return number < 10 ? '0' + number : number;
  }

  function formatSinceLastScore(sinceLastScore) {
    if (!sinceLastScore || sinceLastScore === "" || sinceLastScore === " " || sinceLastScore === 0) {
      return '-';
    }
  
    const inputTimestamp = sinceLastScore * 1000; // Convert from seconds to milliseconds
    const currentTimestamp = Date.now();
    const differenceInMs = currentTimestamp - inputTimestamp;
  
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
    const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  
    if (differenceInMinutes < 60) {
      return `${differenceInMinutes}m`;
    } else if (differenceInHours < 24) {
      const hours = differenceInHours;
      const minutes = differenceInMinutes % 60;
      return `${hours}h ${minutes}m`;
    } else {
      const days = differenceInDays;
      const hours = differenceInHours % 24;
      const minutes = differenceInMinutes % 60;
      return `${days}d ${hours}h ${minutes}m`;
    }
  }

  function formatTimestamp(timestamp) {
    if (!timestamp) {
      return '-';
    }

    const milliseconds = timestamp * 1000;
    const date = new Date(milliseconds);

    const day = date.getDate();
    const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hour = addLeadingZero(date.getHours());
    const minute = addLeadingZero(date.getMinutes());
    const second = addLeadingZero(date.getSeconds());

    const formattedDateTime = `${day}/${month}/${year} ${hour}:${minute}:${second}`;

    return formattedDateTime;
  }

  function formatTimestampFirebaseFormat(timestamp) {
    if (!timestamp || typeof timestamp !== 'object' || !timestamp.seconds) {
        return '-';
    }

    // Convert Firestore Timestamp to milliseconds
    const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6);
    const date = new Date(milliseconds);

    const day = addLeadingZero(date.getDate());
    const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hour = addLeadingZero(date.getHours());
    const minute = addLeadingZero(date.getMinutes());
    const second = addLeadingZero(date.getSeconds());

    const formattedDateTime = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    return formattedDateTime;
  }

  // Helper function to add leading zeros
  function addLeadingZero(number) {
    return number < 10 ? `0${number}` : number;
  }

  const classifiedIssues = data.reduce((result, item) => {
    const installationKey = `${item.Installation.Center}-${item.Installation.Dev_type}-${item.Installation.ID}-${item.Installation.Organization}-${item.Installation.Unit_ID}`;
    const issueKey = `${item.Issue.ID}-${item.Issue.number}-${item.Issue.type}-${item.Issue.status}`;

    const combinedKey = `${installationKey}-${issueKey}`;
    if (!result[combinedKey]) {
      result[combinedKey] = [];
    }
    result[combinedKey].push(item);
    return result;
  }, {});

  const severityOrder = {
    "BackEnd": 4,
    "Critical": 4,
    "Error": 3,
    "Warning": 2,
    "Exception": 1
  };

  const groupedDataByUnitInit = {};

  data.forEach((item) => {
    const key = item.Installation.Unit_ID;

    if (item.timestamps.open === undefined) {
      console.log('No open timestamp:', item);
    }

    if (!(key in groupedDataByUnitInit)) {
      // If the unit key doesn't exist, add the current item
      groupedDataByUnitInit[key] = item;
    } else {
      const currentItem = groupedDataByUnitInit[key];

      const isCurrentItemClosed = currentItem.Issue.status === 'Closed';
      const isNewItemClosed = item.Issue.status === 'Closed';

      if (isCurrentItemClosed && !isNewItemClosed) {
        // Prioritize open items over closed items
        groupedDataByUnitInit[key] = item;
      } else if (!isCurrentItemClosed && !isNewItemClosed) {
        // Both items are open, compare severity
        if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
          groupedDataByUnitInit[key] = item;
        } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
          // Same severity, compare timestamps
          if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
            groupedDataByUnitInit[key] = item;
          }
        }
      } else if (isCurrentItemClosed && isNewItemClosed) {
        // Both items are closed, compare severity
        if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
          groupedDataByUnitInit[key] = item;
        } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
          // Same severity, compare timestamps
          if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
            groupedDataByUnitInit[key] = item;
          }
        }
      }
    }
  });

  const dashboardUnits = {};

  dashboardUnitsData.forEach((unit) => {
    dashboardUnits[unit.unitId] = {
      status: "",
      organization: "",
      center: "",
      unit: unit.unitId,
      unit_status: "",
      time: "",
      time_installed: "",
      since_last_score: "",
      daily_unit_use: "",
      total_kpod_use: "",
      kpod_id: "",
    };
  });

  unitsData.forEach((unitData) => {
    const { unitID, devices } = unitData;
    if (dashboardUnits[unitID]) {
      if (devices && devices.kPodID) {
        dashboardUnits[unitID].kpod_id = devices.kPodID || "";
      }
    }
  });

  for (const key in groupedDataByUnitInit) {
    if (key in dashboardUnits) {
      const item = groupedDataByUnitInit[key];
      const { Installation, Issue, timestamps } = item;
      const time = timestamps?.open?.Created_at || "";
      dashboardUnits[key] = {
        status: Issue.type || "",
        organization: Installation.Organization || "",
        center: Installation.Center || "",
        time: time,
        time_installed: "",
        since_last_score: "",
        daily_unit_use: "",
        total_kpod_use: "-",
        kpod_id: "",
      };
    }
  }

  //setGroupedDataByUnit(groupedDataByUnitInit);

  devicesData.forEach((deviceData) => {
    const { deviceID, totalUsage, installationDate } = deviceData;
    for (const key in dashboardUnits) {
      if (dashboardUnits[key].kpod_id === deviceID) {
        dashboardUnits[key].total_kpod_use = totalUsage || "-";
        dashboardUnits[key].time_installed = installationDate || "-";
      }
    }
  });

  // Function to fetch daily usage averages from Realtime Database
  const fetchDailyUsageAverages = async (unitID, year, month, day) => {
    try {
      const path = `/dailyUnitsUsageAverages/${unitID}/${year}/${month}/${day}/dailyUsage`;
      const snapshot = await ref(database, path);
      let data = "";
      return new Promise((resolve, reject) => {
        onValue(snapshot, (snapshot) => {
        data = snapshot.val();
        resolve(data);
        });
      });
    } catch (error) {
      console.error('Error fetching daily usage averages:', error);
      throw error; // Rethrow the error to handle it higher up in the call stack
    }
  };

  const fetchDailyUnitUsageTimeStamps = async (unitID) => {
    try {
      const path = `/dailyUnitUsageTimeStamps/${unitID}`;
      const snapshotRef = ref(database, path);
      return new Promise((resolve, reject) => {
        onValue(snapshotRef, (snapshot) => {
          const snapshotVal = snapshot.val();
          if (snapshotVal !== null && snapshotVal !== undefined) {
            const keys = Object.keys(snapshotVal);
            if (keys.length > 0) {
              const lastChildKey = keys[keys.length - 1];
              const timeStamp = snapshot.child(lastChildKey).val().timeStamp;
              resolve(timeStamp);
            } else {
              reject(new Error('No data available'));
            }
          } else {
            console.log("fetchTimestamp error info:", unitID);
          }
        }, (error) => {
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error fetching daily unit usage timestamps:', error);
      throw error; // Rethrow the error to handle it higher up in the call stack
    }
  };


  // Fetch data for each unit in dashboardUnits for the current day
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
  const day = String(currentDate.getDate()).padStart(2, '0');

  for (const key in dashboardUnits) {
    const unitID = key;
    if (unitID !== "backend") {
      fetchDailyUsageAverages(unitID, year, month, day)
        .then(dailyUsage => {
          dashboardUnits[unitID].daily_unit_use = dailyUsage === null ? 0 : dailyUsage;
          return fetchDailyUnitUsageTimeStamps(unitID);
        })
        .then(timeStamp => {
          dashboardUnits[unitID].since_last_score = timeStamp;
          dashboardUnits[unitID].unit_status = "active";
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      dashboardUnits[unitID].unit_status = "active";
    }

  }

  const updateDashboardUnits = async (
    updateType = 'all',
    dataInput = undefined,
    devicesDataInput = undefined,
    dashboardUnitsDataInput = undefined,
    unitsDataInput = undefined,
    unitsFirestoreDataInput = undefined,
  ) => {
    // Check if each parameter is undefined, and if so, use an empty array instead
    const dataChecked = dataInput !== undefined ? dataInput : data;
    const devicesDataChecked = devicesDataInput !== undefined ? devicesDataInput : devicesData;
    const dashboardUnitsDataChecked = dashboardUnitsDataInput !== undefined ? dashboardUnitsDataInput : dashboardUnitsData;
    const unitsDataChecked = unitsDataInput !== undefined ? unitsDataInput : unitsData;
    const unitsFirestoreDataChecked = unitsFirestoreDataInput !== undefined ? unitsFirestoreDataInput : unitsFirestoreData;

    if (updateType === "all" || updateType === "issues") {

      var groupedDataByUnit = {};
      if (devicesDataChecked === undefined || devicesDataChecked.length === 0) {
        return groupedDataByUnit;
      }

      // Initialize groupedDataByUnit with keys from dashboardUnitsDataChecked
      dashboardUnitsDataChecked.forEach(unit => {
        groupedDataByUnit[unit.unitId] = null;
      });

      dataChecked.forEach((item) => {
        const key = item.Installation.Unit_ID;

        // Skip if the key does not exist in groupedDataByUnit
        if (!(key in groupedDataByUnit)) {
          return;
        }

        if (item.timestamps.open === undefined) {
          console.log('No open timestamp:', item);
        }

        if (groupedDataByUnit[key] === null) {
          // If the unit key doesn't have any item yet, add the current item
          groupedDataByUnit[key] = item;
        } else {
          const currentItem = groupedDataByUnit[key];

          const isCurrentItemClosed = currentItem.Issue.status === 'Closed';
          const isNewItemClosed = item.Issue.status === 'Closed';

          if (isCurrentItemClosed && !isNewItemClosed) {
            // Prioritize open items over closed items
            groupedDataByUnit[key] = item;
          } else if (!isCurrentItemClosed && !isNewItemClosed) {
            // Both items are open, compare severity
            if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
              groupedDataByUnit[key] = item;
            } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
              // Same severity, compare timestamps
              if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
                groupedDataByUnit[key] = item;
              }
            }
          } else if (isCurrentItemClosed && isNewItemClosed) {
            // Both items are closed, compare severity
            if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
              groupedDataByUnit[key] = item;
            } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
              // Same severity, compare timestamps
              if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
                groupedDataByUnit[key] = item;
              }
            }
          }
        }
      });

    }
    var dashboardUnits = {};


    dashboardUnits = { ...dashboardUnitsComplete };


    if (updateType === "all" || updateType === "units") {

      dashboardUnitsDataChecked.forEach((unit) => {
        const unitExists = Object.values(dashboardUnits).some(obj => obj.unit === unit.unitId);
        if (!unitExists) {
          dashboardUnits[unit.unitId] = {
            organization: "",
            center: "",
            unit: unit.unitId,
            unit_status: "",
            time: "",
            time_installed: "",
            since_last_score: "",
            daily_unit_use: "",
            total_kpod_use: "-",
            kpod_id: "",
            isDisconnected: "",
            description: "",
            rosterActive: "",
            lastRosterLoginTimestamp: "",
            lastInitTimestamp: "",
            notes: "",
            isSit: "",
            priority: "",
            clogged: "",
            dailyUptime: "",
          };
        }
      });

      var usesCounter = 0;
      var dailyUptimeSum = 0.0;
      var activeUnitsCount = 0;

      unitsDataChecked.forEach((unitData) => {
        const matchingUnitKey = Object.keys(dashboardUnits).find(unitKey => dashboardUnits[unitKey].unit === unitData.unitID);
        if (!matchingUnitKey) return;

        const {
          unitID,
          cartridgeUsageCounter,
          status,
          devices,
          isUnitDisconnected,
          description,
          organizationName,
          centerName,
          rosterActive,
          lastRosterLoginTimestamp,
          lastInitTimestamp,
          dailyUsageCounter,
          isSit,
          priority,
          clogged,
          dailyUptime,
          lastScoreTimestamp,
        } = unitData;

        usesCounter += dailyUsageCounter;
        dailyUptimeSum += dailyUptime;
        if (status === "active") {
          activeUnitsCount++;
        }

        if (devices) {
          dashboardUnits[matchingUnitKey].kpod_id = devices.kPodID || "-";
          dashboardUnits[matchingUnitKey].organization = organizationName || "-";
          dashboardUnits[matchingUnitKey].center = centerName || "-";
        }
        dashboardUnits[matchingUnitKey].isDisconnected = isUnitDisconnected || false;
        dashboardUnits[matchingUnitKey].rosterActive = rosterActive || false;
        dashboardUnits[matchingUnitKey].lastRosterLoginTimestamp = lastRosterLoginTimestamp || "-";
        dashboardUnits[matchingUnitKey].lastInitTimestamp = lastInitTimestamp || "-";

        dashboardUnits[matchingUnitKey].unit_status = status || "";
        dashboardUnits[matchingUnitKey].description = description || "";
        dashboardUnits[matchingUnitKey].total_kpod_use = cartridgeUsageCounter || "-";
        dashboardUnits[matchingUnitKey].daily_unit_use = dailyUsageCounter === null ? 0 : dailyUsageCounter;
        dashboardUnits[matchingUnitKey].isSit = isSit || false;
        dashboardUnits[matchingUnitKey].priority = priority || false;
        dashboardUnits[matchingUnitKey].clogged = clogged || false;
        dashboardUnits[matchingUnitKey].dailyUptime = dailyUptime || "-";
        dashboardUnits[matchingUnitKey].since_last_score = lastScoreTimestamp || "-";
      });

      setTotalDailyUses(usesCounter);
      var meanDailyUptimePercentage = activeUnitsCount > 0
        ? Math.round((dailyUptimeSum / activeUnitsCount / 86400) * 100)
        : 0;

      setTotalDailyUptime(meanDailyUptimePercentage);

    }

    if (updateType === "all" || updateType === "issues") {
      // Fill additional details from groupedDataByUnit
      for (const key in groupedDataByUnit) {
        const matchingUnitKey = Object.keys(dashboardUnits).find(unitKey => dashboardUnits[unitKey].unit === key);
        if (groupedDataByUnit[key] !== null && matchingUnitKey) {
          const item = groupedDataByUnit[key];
          if (item) {
            const { Issue, timestamps } = item;
            const time = timestamps?.open?.Created_at || "-";
            dashboardUnits[matchingUnitKey] = {
              ...dashboardUnits[matchingUnitKey], // Preserve existing data
              status: Issue.type || "",
              time: time,
            };
            if (dashboardUnits[matchingUnitKey].organization === "" || dashboardUnits[matchingUnitKey].center === "") {
              dashboardUnits[matchingUnitKey] = {
                ...dashboardUnits[matchingUnitKey], // Preserve existing data
                organization: item.Installation.Organization || "-",
                center: item.Installation.Center || "-",
              };
            }
          }
        } else {
          const matchingUnitKeyForNull = Object.keys(dashboardUnits).find(unitKey => dashboardUnits[unitKey].unit === key);
          if (groupedDataByUnit[key] === null && matchingUnitKeyForNull) {
            groupedDataByUnit[key] = { Issue: { status: "Closed" } };
          }
        }
      }
      setGroupedDataByUnit(groupedDataByUnit);
    }

    if (updateType === "all" || updateType === "units") {

      const unitsNotesMap = {};
      unitsFirestoreDataChecked.forEach(({ unitID, notes }) => {
        unitsNotesMap[unitID] = notes || "";
      });

      // Convert `devicesDataChecked` into a lookup map (for quick access)
      const devicesInstallDateMap = {};
      devicesDataChecked.forEach(({ deviceID, installationDate }) => {
        devicesInstallDateMap[deviceID] = installationDate || "-";
      });

      // Single iteration through `dashboardUnits`
      for (const key in dashboardUnits) {
        const unit = dashboardUnits[key];

        // Update notes if the unit exists in `unitsNotesMap`
        if (unitsNotesMap[unit.unit]) {
          unit.notes = unitsNotesMap[unit.unit];
        }

        // Update installation date if the unit exists in `devicesInstallDateMap`
        if (devicesInstallDateMap[unit.kpod_id]) {
          unit.time_installed = devicesInstallDateMap[unit.kpod_id];
        }

        // Handle backend-specific logic
        if (key === "backend") {
          unit.unit_status = "active";
          unit.daily_unit_use = 0;
          unit.organization = "Unknown Organization";
          unit.center = "Unknown Center";
        }
      }
    }
    return dashboardUnits;
  }

  useEffect(() => {
    if (userName === null) return;
  
    const updateFromIssues = async () => {
      const dashboardData = await updateDashboardUnits("issues", data);
      const updatedData = filterAndSortDashboardUnits(dashboardData);
      setDashboardUnitsComplete(updatedData);
      sortDashboardUnits(updatedData);
    };
  
    updateFromIssues();
  }, [data]);
  
  useEffect(() => {
    if (userName === null) return;
  
    const updateFromUnits = async () => {
      const dashboardData = await updateDashboardUnits("units", undefined, unitsData);
      const updatedData = filterAndSortDashboardUnits(dashboardData);
      setDashboardUnitsComplete(updatedData);
      sortDashboardUnits(updatedData);
    };
  
    updateFromUnits();
  }, [unitsData]);
  
  useEffect(() => {
    if (userName === null) return;
  
    const updateFromBoth = async () => {
      const dashboardData = await updateDashboardUnits("all", data, unitsData);
      const updatedData = filterAndSortDashboardUnits(dashboardData);
      setDashboardUnitsComplete(updatedData);
      sortDashboardUnits(updatedData);
    };
  
    updateFromBoth();
  }, [dashboardUnitsData]);

  const filterAndSortDashboardUnits = (dashboardData) => {
    return Object.values(dashboardData).filter((item) => {
      if (unitIdFilter !== '' && !item.unit?.toString().toLowerCase().startsWith(unitIdFilter.toLowerCase())) {
        return false;
      }
      if (organizationFilter && item.organization?.toLowerCase() !== organizationFilter.toLowerCase()) {
        return false;
      }
      if (centerFilter && item.center?.toLowerCase() !== centerFilter.toLowerCase()) {
        return false;
      }
      if (issueTypeInDashboardFilter !== '' && item.status?.toLowerCase() !== issueTypeInDashboardFilter.toLowerCase()) {
        return false;
      }
      return true;
    });
  };


  const classifiedIssuesbyUnit = data.reduce((result, item) => {

    const combinedKey = item.Installation.Unit_ID;

    if (!result[combinedKey]) {
      result[combinedKey] = [];
    }

    result[combinedKey].push(item);

    return result;
  }, {});

  const classifiedIssuesbyUnitAndKey = data.reduce((result, item) => {
    const unitKey = item.Installation.Unit_ID;
    const installationKey = `${item.Installation.Center}-${item.Installation.Dev_type}-${item.Installation.ID}-${item.Installation.Organization}-${item.Installation.Unit_ID}`;
    const issueKey = `${item.Issue.ID}-${item.Issue.number}-${item.Issue.type}-${item.Issue.status}`;
    const combinedKey = `${installationKey}-${issueKey}`;

    if (!result[unitKey]) {
        result[unitKey] = {};
    }

    if (!result[unitKey][combinedKey]) {
        result[unitKey][combinedKey] = [];
    }

    result[unitKey][combinedKey].push(item);

    return result;
  }, {});

  const lastItemsByUnitAndIssue = {};

  // Iterate through each installation
  for (const unitKey in classifiedIssuesbyUnitAndKey) {
      const installation = classifiedIssuesbyUnitAndKey[unitKey];
      lastItemsByUnitAndIssue[unitKey] = [];

      // Iterate through each issue within the installation
      for (const issueKey in installation) {
          const issueItems = installation[issueKey];

          // Find the item with the latest timestamps.open.Created_at
          const lastItem = issueItems.reduce((last, current) => {
              return current.timestamps.open.Created_at > last.timestamps.open.Created_at ? current : last;
          });

          // Store the last item for this issue
          lastItemsByUnitAndIssue[unitKey].push(lastItem);
      }
  }

  const compareByCriteria = (a, b) => {
    // First criteria: unit_status ("active" first, "disabled" last)
    if (a.unit_status !== b.unit_status) {
      return a.unit_status === "active" ? -1 : 1;
    }

    // Check if the status of the issues is "Closed" for second and third criteria
    const aStatusClosed = groupedDataByUnit[a.unit]?.Issue?.status === "Closed";
    const bStatusClosed = groupedDataByUnit[b.unit]?.Issue?.status === "Closed";

    if (aStatusClosed && !bStatusClosed) {
      return 1;  // a should come after b if a is "Closed" and b is not
    }

    if (!aStatusClosed && bStatusClosed) {
      return -1; // a should come before b if a is not "Closed" and b is "Closed"
    }

    // Second criteria: status severity
    if (severityOrder[a.status] !== severityOrder[b.status]) {
      return severityOrder[b.status] - severityOrder[a.status];
    }

    // Third criteria: time (timestamp, higher values first)
    const timeA = typeof a.time === "number" ? a.time : 0;
    const timeB = typeof b.time === "number" ? b.time : 0;
    return timeB - timeA;
  };

  function sortDashboardUnits(dashboardUnitsUpdated) {
    const dashboardUnitsSorted = Object.values(dashboardUnitsUpdated).sort((a, b) => {
      // Default criteria: unit_status, status, time
      if (sortField === 'unit_status' || sortField === 'status' || sortField === 'time') {
        return compareByCriteria(a, b);
      }

      // Custom sorting based on sortField
      const compareA = getProperty(a, sortField)?.toString();
      const compareB = getProperty(b, sortField)?.toString();

      if (compareA === null || compareA === undefined) {
        return sortOrder === 'asc' ? 1 : -1;
      }

      if (compareB === null || compareB === undefined) {
        return sortOrder === 'asc' ? -1 : 1;
      }

      if (sortOrder === 'asc') {
        return compareA.localeCompare(compareB, undefined, { numeric: true });
      } else {
        return compareB.localeCompare(compareA, undefined, { numeric: true });
      }
    });
    setDashboardUnitsUpdated(dashboardUnitsSorted);
  }

  useEffect(() => {
    const dashboardUnitsSorted = Object.values(dashboardUnitsUpdated).sort((a, b) => {
      if (dashboardUnitsUpdated !== undefined) {
        const compareA = getProperty(a, sortField)?.toString();
        const compareB = getProperty(b, sortField)?.toString();

        if (compareA === null || compareA === undefined) {
          return sortOrder === 'asc' ? 1 : -1;
        }

        if (compareB === null || compareB === undefined) {
          return sortOrder === 'asc' ? -1 : 1;
        }

        if (sortOrder === 'asc') {
          return compareA.localeCompare(compareB, undefined, { numeric: true });
        } else {
          return compareB.localeCompare(compareA, undefined, { numeric: true });
        }
      }
    });
    setDashboardUnitsUpdated(dashboardUnitsSorted);
  }, [sortField, sortOrder]);

  useEffect(() => {
    const issuesSorted = Object.values(currentIssuesList).sort((a, b) => {
      if (currentIssuesList !== undefined) {
        const compareA = getProperty(a, sortFieldIssue)?.toString();
        const compareB = getProperty(b, sortFieldIssue)?.toString();

        if (compareA === null || compareA === undefined) {
          return sortOrderIssue === 'asc' ? 1 : -1;
        }

        if (compareB === null || compareB === undefined) {
          return sortOrderIssue === 'asc' ? -1 : 1;
        }

        if (sortOrderIssue === 'asc') {
          return compareA.localeCompare(compareB, undefined, { numeric: true });
        } else {
          return compareB.localeCompare(compareA, undefined, { numeric: true });
        }
      }
    });
    setCurrentIssuesList(issuesSorted);
  }, [sortFieldIssue, sortOrderIssue]);

  useEffect(() => {
    if (dashboardUnitsComplete.length !== 0) {
      const updatedData = Object.values(dashboardUnitsComplete).filter((item) => {
        if (unitIdFilter !== '' && !item.unit?.toString().toLowerCase().startsWith(unitIdFilter.toLowerCase())) {
          return false;
        }
        if (organizationFilter && item.organization?.toLowerCase() !== organizationFilter.toLowerCase()) {
          return false;
        }
        if (centerFilter && item.center?.toLowerCase() !== centerFilter.toLowerCase()) {
          return false;
        }
        if (issueTypeInDashboardFilter !== '' && item.status?.toLowerCase() !== issueTypeInDashboardFilter.toLowerCase()) {
          return false;
        }
        return true;
      });
      sortDashboardUnits(updatedData);
    }
  }, [unitIdFilter, organizationFilter, centerFilter, issueTypeInDashboardFilter]);

  useEffect(() => {
    handleCurrentIssuesList(selectedUnit);
  }, [selectedUnit, groupIssues, issueIdFilter, issueTypeFilter, issueStatusSelected, issueIDsSelected]);

  const handleCurrentIssuesList = (unit) => {
    var filteredDataByUnit = [];
    if (lastItemsByUnitAndIssue[unit]) {
      filteredDataByUnit = (groupIssues ? lastItemsByUnitAndIssue[unit] : classifiedIssuesbyUnit[unit]).filter((item) => {
        if (issueIdFilter && !item.Issue.ID.toString().toLowerCase().startsWith(issueIdFilter.toLowerCase())) {
          return false;
        }
        if (issueTypeFilter && item.Issue.type !== issueTypeFilter) {
          return false;
        }
        if (!issueStatusSelected.some(status => status.value === item.Issue.status)) {
          return false;
        }
        if (issueIDsSelected.includes(item.Issue.ID)) {
          return false; // Exclude items with Issue.ID in issueIDsSelected
        }
        return true;
      });
    }
    const issuesSorted = Object.values(filteredDataByUnit).sort((a, b) => {
      if (filteredDataByUnit !== undefined) {
        const compareA = getProperty(a, sortFieldIssue)?.toString();
        const compareB = getProperty(b, sortFieldIssue)?.toString();

        if (compareA === null || compareA === undefined) {
          return sortOrderIssue === 'asc' ? 1 : -1;
        }

        if (compareB === null || compareB === undefined) {
          return sortOrderIssue === 'asc' ? -1 : 1;
        }

        if (sortOrderIssue === 'asc') {
          return compareA.localeCompare(compareB, undefined, { numeric: true });
        } else {
          return compareB.localeCompare(compareA, undefined, { numeric: true });
        }
      }
    });
    setCurrentIssuesList(issuesSorted);
    setIssueIDs(Array.from(new Set(filteredDataByUnit.map(item => item.Issue.ID))));
  }




  const getProperty = (obj, path) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  // Fetch units snapshot

  useEffect(() => {
    dashboardUnitsDataRef.current = dashboardUnitsData;
  }, [dashboardUnitsData]);

  const handleAddedUnit = (unit) => {
    setUnitsData((prevData) => {
      const newData = [...prevData, unit];
      updateDashboardUnitChartData(newData);
      return newData;
    });
  };

  const handleModifiedUnit = (unit) => {
    setUnitsData((prevData) => {
      const updatedUnits = prevData.map((existingUnit) =>
        existingUnit.id === unit.id ? { ...existingUnit, ...unit } : existingUnit
      );
      updateDashboardUnitChartData(updatedUnits);
      return updatedUnits;
    });
  };

  const handleRemovedUnit = (unit) => {
    setUnitsData((prevData) => {
      const updatedUnits = prevData.filter((existingUnit) => existingUnit.id !== unit.id);
      updateDashboardUnitChartData(updatedUnits);
      return updatedUnits;
    });
  };

  const updateDashboardUnitChartData = (updatedUnits) => {
    const dashboardUnitIds = dashboardUnitsDataRef.current.map(unit => unit.unitId);
    const nonMatchingUnits = updatedUnits.filter(unit => dashboardUnitIds.includes(unit.unitID));
    updateUnitChartData(nonMatchingUnits);
  };

  useEffect(() => {
    if (userName === null) {
      return;
    }

    const unitsRef = ref(database, "/units");

    // Listen for added units
    const unsubscribeAdded = onChildAdded(unitsRef, (snapshot) => {
        const addedUnit = { id: snapshot.key, ...snapshot.val() };
        handleAddedUnit(addedUnit);
    });

    // Listen for modified units
    const unsubscribeChanged = onChildChanged(unitsRef, (snapshot) => {
        const modifiedUnit = { id: snapshot.key, ...snapshot.val() };
        handleModifiedUnit(modifiedUnit);
    });

    // Listen for removed units
    const unsubscribeRemoved = onChildRemoved(unitsRef, (snapshot) => {
        const removedUnit = { id: snapshot.key };
        handleRemovedUnit(removedUnit);
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeAdded();
      unsubscribeChanged();
      unsubscribeRemoved();
    };
  }, []);

  // Fetch issues snapshot
  
  useEffect(() => {
    criticalIssuesDataRef.current = criticalIssuesData;
  }, [criticalIssuesData]);

  useEffect(() => {
    if (userName === null) {
      return;
    }
  
    const kamEyeRef = ref(database, "/kam_eye");
  
    // Listen for added issues
    const unsubscribeAdded = onChildAdded(kamEyeRef, (snapshot) => {
      const addedIssue = { id: snapshot.key, ...snapshot.val() };
      handleAddedIssue(addedIssue);
    });
  
    // Listen for modified issues
    const unsubscribeChanged = onChildChanged(kamEyeRef, (snapshot) => {
      const modifiedIssue = { id: snapshot.key, ...snapshot.val() };
      handleModifiedIssue(modifiedIssue);
    });
  
    // Listen for removed issues
    const unsubscribeRemoved = onChildRemoved(kamEyeRef, (snapshot) => {
      const removedIssue = { id: snapshot.key };
      handleRemovedIssue(removedIssue);
    });
  
    // Cleanup listeners on unmount
    return () => {
      unsubscribeAdded();
      unsubscribeChanged();
      unsubscribeRemoved();
    };
  }, []);

  const handleAddedIssue = (issue) => {
    updateIssueType(issue, criticalIssuesDataRef.current);
    setData((prevData) => {
      const newData = [...prevData, issue];
      updateChartsAndCounters(newData);
      return newData;
    });
  };

  const handleModifiedIssue = (issue) => {
    updateIssueType(issue, criticalIssuesDataRef.current);
    setData((prevData) => {
      const updatedIssues = prevData.map((existingIssue) =>
        existingIssue.id === issue.id ? { ...existingIssue, ...issue } : existingIssue
      );
      updateChartsAndCounters(updatedIssues);
      return updatedIssues;
    });
  };

  const handleRemovedIssue = (issue) => {
    setData((prevData) => {
      const updatedIssues = prevData.filter((existingIssue) => existingIssue.id !== issue.id);
      updateChartsAndCounters(updatedIssues);
      return updatedIssues;
    });
  };

  const updateIssueType = (issue, criticalIssues) => {
    // Using the criticalIssuesData state to check if the issue is critical
    const issueId = issue.Issue?.ID?.toLowerCase();

    if (issueId && criticalIssues.size > 0 && criticalIssues.has(issueId)) {
      issue.Issue.type = "Critical";
    }
  };

  const updateChartsAndCounters = (updatedIssues) => {
    setData(updatedIssues);
    updatePieChartData(updatedIssues);
    updateBarChartData(updatedIssues);

    // Filter data for the last seven days and update state counter data
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserSessionPersistence);

        const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
          navigate('/');
          if (user) {
            setUser(user);
            const uid = user.uid;

            const userDocRef = doc(db, 'users', uid);
            const userDocSnapshot = await getDoc(userDocRef);

            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              if (!(
                userData.kamleonPermissions.userRole === 'kamleon_admin' ||
                userData.kamleonPermissions.userRole === 'kamleon_editor' ||
                userData.kamleonPermissions.userRole === 'kamleon_viewer' ||
                userData.kamleonPermissions.userRole === 'kamleon_owner'
              )) {
                // User does not have the necessary permissions
                auth.signOut();
                setShowAuthModal(true);
                setShowApp(false);
                setLoading(false);
                return;
              }
              sessionStorage.setItem('userName', userData.name);
              sessionStorage.setItem('userLastName', userData.lastName);
              sessionStorage.setItem('userEmail', userData.email);
              sessionStorage.setItem('userRole', userData.kamleonPermissions.userRole);
              await fetchData();
              await fetchUsers();
              setShowDashboard(true);
              setShowApp(true);
              setShowAuthModal(false);
              setLoading(false);
            } else {
              console.log('User document not found.');
              setShowAuthModal(true);
              setShowApp(false);
              setLoading(false);
            }
          } else {
            setUser(null);
            setShowAuthModal(true);
            setShowApp(false);
            console.log('No user is currently authenticated.');
            setLoading(false);
          }
        });


        // Cleanup function
        return () => {
          unsubscribeAuth();
        };
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      sessionStorage.removeItem('userName');
      setUser(null);
      setShowAuthModal(true);
      setShowApp(false);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  const setNewNotes = async (newNotes) => {
    try {
      // Update the local dashboardUnits data
      dashboardUnits[selectedUnit].notes = newNotes;
      const updatedDashboardUnits = dashboardUnitsUpdated.map((unit) =>
        unit.unit === selectedUnit
          ? { ...unit, notes: newNotes } // Update the notes field for the matching unit
          : unit // Leave other units unchanged
      );
  
      // Set the updated data back into the state or variable
      setDashboardUnitsUpdated(updatedDashboardUnits);
  
      const unitDocRef = doc(db, 'units', selectedUnit); // Reference to the document

      // Update the notes field in Firestore
      await updateDoc(unitDocRef, { notes: newNotes });

      // Update the local unitsFirestoreData
      setUnitsFirestoreData((prevData) =>
        prevData.map((unit) =>
          unit.name === selectedUnit ? { ...unit, notes: newNotes } : unit
        )
      );

    } catch (error) {
      console.error('Error updating notes:', error);
    }
  };

  const handleUnit = (unit) => {
    setSelectedUnitItem(unit);
    setSelectedUnit(unit.unit);
    setSelectedUnitCenter(unit.center);
    setSelectedUnitNotes(unit.notes);
      handleCurrentIssuesList(unit.unit);
      setShowDashboard(false);
      setShowIssuesTable(true);
      if (!(unit.unit in dashboardUnits && dashboardUnits[unit.unit].status !== "")) {
        setIssueToModify({Issue: {Installation: {Unit_ID: unit.unit, Center: unit.center}}});
      }
    // Navigate to the issues table route
    navigate(`/issues?unit=${unit.unit}&center=${unit.center}`);
  }

  const backToDashboard = () => {
    setIssueStatusSelected([
      { value: "Open", label: "Open" },
      { value: "In progress", label: "In progress" },
    ]);
    setSelectedUnit(null);
    setShowIssuesTable(false);
    setShowDashboard(true);
    navigate('/');
  }

  const handleHandleIssue = (item, issueGroup) => {
    setShowIssuesTable(false);
    setShowHandleIssue(true);
    setIssueToModify(item);
    // deleteDocuments(issueGroup);
    // deleteOldDocuments();
    if (groupIssues && issueGroup.length > 1) {
      setIssueGroupToModify(issueGroup);
    }
    else {
      setIssueGroupToModify({});
    }
    // Navigate to the handle issue route
    navigate(`/handle-issue?id=${item.id}`);
  };

  const handleMultiSelectChange = async (selectedOptions) => {
    setIssueStatusSelected(selectedOptions);
    if (selectedOptions.some((option) => option.value === 'Closed')) {
      const closedIssuesForUnitPromise = await getDocs(
        query(
          kam_eye_issues,
          where("Issue.status", "==", "Closed"),
          where("Installation.Unit_ID", "==", selectedUnit)
        )
      );
      const closedIssuesForUnitData = closedIssuesForUnitPromise.docs.map(doc => doc.data());
      setData([...data, ...closedIssuesForUnitData]);
    }
  };

  const handleHideIssuesMultiSelectChange = (selectedOptions) => {
    setIssueIDsSelected(selectedOptions.map(option => (option.value)));
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.hasValue ? '#FC664E' : 'white', // Change background color when value is selected
    }),
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setShowDashboard(true);
    } else {
      setShowDashboard(false);
    }
  }, [location]);

  const statusOptions = [
    { value: "looped", label: "Looped" },
    { value: "clogged", label: "Clogged" },
    { value: "unused", label: "Unused" },
    { value: "disconnected", label: "Disconnected" },
    { value: "disabled", label: "disabled" },
  ];

  const getDefaultStatuses = (selectedUnitItem, currentIssuesList) => {
    const preSelected = [];

    if (selectedUnitItem.daily_unit_use === 0 || selectedUnitItem.daily_unit_use === "") {
      preSelected.push({ value: "unused", label: "Unused" });
    }
    if (selectedUnitItem.isDisconnected) {
      preSelected.push({ value: "disconnected", label: "Disconnected" });
    }
    if (currentIssuesList.some(issue => issue.ID === "FE16")) {
      preSelected.push({ value: "looped", label: "Looped" });
    }
    if (selectedUnitItem.unit_status === "disabled") {
      preSelected.push({ value: "disabled", label: "Disabled" });
    }
    if (selectedUnitItem.clogged === true) {
      preSelected.push({ value: "clogged", label: "Clogged" });
    }

    return preSelected;
  };

  const handleStatusChange = async (selectedOptions, unitId) => {
    try {
      const isClogged = selectedOptions.some(option => option.value === 'clogged');
      
      // Update in Realtime Database
      const unitRef = ref(database, `/units/${unitId}`);
      await set(unitRef, {
        ...unitsData.find(unit => unit.unitID === unitId),
        clogged: isClogged
      });
  
      // Update local state
      setUnitsData(prevData => 
        prevData.map(unit => 
          unit.unitID === unitId 
            ? { ...unit, clogged: isClogged }
            : unit
        )
      );
  
    } catch (error) {
      console.error('Error updating unit status:', error);
    }
  };

  const StatusMultiSelect = ({ selectedUnitItem, currentIssuesList, onChange }) => {
    const [selectedStatuses, setSelectedStatuses] = useState([]);
  
    useEffect(() => {
      // Update selected statuses whenever the input data changes
      const newStatuses = [];
  
      // Check daily_unit_use
      if (selectedUnitItem.daily_unit_use === 0 || selectedUnitItem.daily_unit_use === "") {
        newStatuses.push({ value: "unused", label: "Unused" });
      }
  
      // Check disconnected status
      if (selectedUnitItem.isDisconnected) {
        newStatuses.push({ value: "disconnected", label: "Disconnected" });
      }
  
      // Check FE16 issue
      const hasFE16 = currentIssuesList.some(issue => 
        issue.Installation?.Unit_ID === selectedUnitItem.unit && 
        issue.Issue?.ID === "FE16"
      );
      if (hasFE16) {
        newStatuses.push({ value: "looped", label: "Looped" });
      }
  
      // Check disabled status
      if (selectedUnitItem.unit_status === "disabled") {
        newStatuses.push({ value: "disabled", label: "Disabled" });
      }
  
      // Check clogged status
      if (selectedUnitItem.clogged === true) {
        newStatuses.push({ value: "clogged", label: "Clogged" });
      }
  
      setSelectedStatuses(newStatuses);
    }, [
      selectedUnitItem.daily_unit_use,
      selectedUnitItem.isDisconnected,
      selectedUnitItem.unit_status,
      selectedUnitItem.clogged,
      selectedUnitItem.unit,
      currentIssuesList
    ]);
  
    const handleClick = (event) => {
      event.stopPropagation();
    };
  
    const handleSelectChange = (newSelectedOptions) => {
      setSelectedStatuses(newSelectedOptions);
      handleStatusChange(newSelectedOptions, selectedUnitItem.unit);
    };
  
    return (
      <div onClick={handleClick}>
        <Select
          value={selectedStatuses}
          isMulti
          name="status"
          options={statusOptions}
          onChange={handleSelectChange}
          className="basic-multi-select dashboard-multiselect"
          classNamePrefix="select"
        />
      </div>
    );
  };

  return (
    <div className="App">
      {showApp && (
        <div >
          <div className="row pt-1 pb-2 mx-0" style={{ borderBottom: '1px solid grey', overflowX: 'hidden' }}>
            <div className="col-6 pe-0 ps-3">
              <div className="text-start">
                <KamleonLogo style={{ width: '115px', height: '16px' }} />
              </div>
            </div>
            <div className="col-6 ps-0 pe-3">
              <div className="d-flex align-items-center">
                <div className="ms-auto d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-link px-0 me-4"
                    onClick={() => { setShowIssueSettings(!showIssueSettings) }}
                  >
                    <GearIcon style={{ width: '25px', height: '25px' }} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-link px-0"
                    onClick={handleLogout}
                  >
                    <LogOut style={{ width: '24px', height: '24px' }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        <div className="container">
          {showMqttModal && (
            <MqttComponent place="unit" issue={{Installation: {Unit_ID: selectedUnit, Center: selectedUnitCenter}}} onShow={showMqttModal} onClose={() => { setShowMqttModal(false) }} userRole={userRole} units={unitsData} devices={devicesData} />
          )}
          <Routes>
            <Route path="/" element={
              <div className='py-4'>
                <div className="text-start mb-3">
                  <h5><strong>MONITORING</strong></h5>
                </div>
                <div className="row d-flex align-items-stretch pb-4">
                  <div className="col-2">
                    <DailyUptimeCounter inputData={totalDailyUptime}/>
                  </div>
                  <div className="col-3">
                    {/* <StateCounter inputData={stateCounteData} /> */}
                    <UsesCounter inputData={totalDailyUses}/>
                  </div>
                  <div className="col-3">
                    <UnitActivityChart inputData={unitChart} />
                  </div>
                  <div className="col-4">
                    <PieChart inputData={pieChartData} />
                  </div>
                </div>
                <div className="row g-3 pb-4">
                  <div className="col-3 me-2">
                    <select
                      className='form-select'
                      value={organizationFilter}
                      onChange={(e) => setOrganizationFilter(e.target.value)}
                    >
                      <option value="">All Organizations</option>
                      {Array.from(new Set(Object.values(dashboardUnitsComplete).map(item => item.organization))).map((organization, index) => (
                        <option key={index} value={organization}>{organization}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 ms-0 p-0 me-2">
                    <select
                      className='form-select'
                      value={centerFilter}
                      onChange={(e) => setCenterFilter(e.target.value)}
                    >
                      <option value="">All Centers</option>
                      {Array.from(new Set(Object.values(dashboardUnitsComplete).map(item => item.center))).map((center, index) => (
                        <option key={index} value={center}>{center}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 ms-0">
                    <select
                      className='form-select'
                      value={issueTypeInDashboardFilter}
                      onChange={(e) => {
                        setIssueTypeInDashboardFilter(e.target.value);
                      }}
                    >
                      <option value="">All Issue Types</option>
                      <option value="BackEnd">Backend</option>
                      <option value="Critical">Critical</option>
                      <option value="Error">Error</option>
                      <option value="Warning">Warning</option>
                      <option value="Exception">Exception</option>
                    </select>
                  </div>
                  <div className="col-2 m-0 mt-3 ms-auto form-search">
                    <div className="input-container ms-0">
                      <span className="icon"><FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: '#212529' }}/></span>
                      <input
                        className='form-control dark-placeholder-color'
                        type="text"
                        placeholder="Search by unit"
                        style={{ borderRadius: '21px' }}
                        value={unitIdFilter}
                        onChange={(e) => {
                          setUnitIdFilter(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-wrapper pb-0">
                <table className="table table-striped table-hover">
                  <colgroup>
                    <col style={{ width: "25%" }}/>
                    <col style={{ width: "40%" }}/>
                    <col style={{ width: "15%" }}/>
                    <col style={{ width: "10%" }}/>
                    <col style={{ width: "10%" }}/>
                  </colgroup>
                  <thead className="table-dark rounded-top">
                    <tr>
                      <th onClick={() => handleSort('unit')}>
                        UNIT
                        {/* <span className={`arrow ${sortField === 'timestamps.open.Created_at' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('status')}>
                        STATUS
                        {/* <span className={`arrow ${sortField === 'Issue.type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('since_last_score')}>
                        SINCE LAST SCORE
                        {/* <span className={`arrow ${sortField === 'Installation.Dev_type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('total_kpod_use')}>
                        DAILY UNIT USE
                        {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('total_kpod_use')}>
                        TOTAL CYCLE KPOD
                        {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(dashboardUnitsUpdated).map((item) => {
                      return (
                        <tr
                          key={item.unit}
                          onClick={() => handleUnit(item)}
                          style={{ cursor: 'pointer' }}
                          // className={
                          //   item.unit_status === "disabled"
                          //   ? ''
                          //   : item.isDisconnected
                          //   ? 'alert-row'
                          //   : item.unit === "backend"
                          //   ? ''
                          //   : item.daily_unit_use === 0
                          //   ? 'orange-row'
                          //   : ''
                          // }
                        >
                          <td>
                            {item.priority ? (
                              <span style={{ backgroundColor: "inherit", color: "#FAC63E", WebkitTextStroke: "0.8px black" }}>★ </span> // Filled yellow star
                            ) : (
                              <span style={{ backgroundColor: "inherit", WebkitTextStroke: "0.4px black" }}>☆ </span>
                            )}
                            {item.unit} {item.isSit ? "[S] " : " "} 
                            {item.unit !== "backend" ? item.organization : ""}
                          </td>
                          <td>
                            <StatusMultiSelect
                              selectedUnitItem={item}
                              currentIssuesList={data}
                              onChange={handleStatusChange}
                            />
                          </td>
                          <td>{item.since_last_score === "-" ? item.since_last_score : formatSinceLastScore(item.since_last_score)}</td>
                          <td>{item.daily_unit_use}</td>
                          <td>{item.total_kpod_use}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                </div>
              </div>
            }/>
            <Route path="/issues" element={
              <IssuesTable
                currentIssuesList={currentIssuesList}
                groupIssues={groupIssues}
                handleSortIssue={handleSortIssue}
                handleHandleIssue={handleHandleIssue}
                handleMultiSelectChange={handleMultiSelectChange}
                handleHideIssuesMultiSelectChange={handleHideIssuesMultiSelectChange}
                setGroupIssues={setGroupIssues}
                issueStatus={issueStatus}
                issueIDs={issueIDs}
                customStyles={customStyles}
                issueStatusSelected={issueStatusSelected}
                issueTypeFilter={issueTypeFilter}
                setIssueTypeFilter={setIssueTypeFilter}
                issueIdFilter={issueIdFilter}
                setIssueIdFilter={setIssueIdFilter}
                formatTimestamp={formatTimestamp}
                selectedUnitItem={selectedUnitItem}
                selectedUnitNotes={selectedUnitNotes}
                setSelectedUnitNotes={setSelectedUnitNotes}
                setNewNotes={setNewNotes}
                classifiedIssues={classifiedIssues}
                backToDashboard={backToDashboard}
                classifiedIssuesbyUnitAndKey={classifiedIssuesbyUnitAndKey}
                userRole={userRole}
                unitsData={unitsData}
                devicesData={devicesData}
              />
            } />
            <Route path="/handle-issue" element={
              <HandleIssue
                issue={issueToModify}
                issueGroup={issueGroupToModify}
                usersData={usersData}
                userName={userName}
                userLastName={userLastName}
                onShow={() => {
                    setShowHandleIssue(true);
                }}
                onClose={() => {
                  setShowHandleIssue(false);
                  setShowIssuesTable(true);
                  navigate(`/issues?unit=${issueToModify.Installation.Unit_ID}&center=${issueToModify.Installation.Center}`);
                }}
                userRole={userRole}
                units={unitsData}
                devices={devicesData}
              />
            } />
          </Routes>
          <div className="text-center mt-3 text-muted"><i>Version 1.4.4</i></div>
        </div>
      </div>
      )}
      {showAuthModal && (
        <Auth
          onShow={showAuthModal}
          onClose={() => {
            setShowAuthModal(false)
            setShowApp(true)
          }}
          user={user}
          setUser={setUser}
          // onSignUpClick={handleSignUpClick}
          showAuthModal={showAuthModal}
          loading={loading}
          setLoading={setLoading}
          email={email}
        />
      )}
      {showMqttComponent && (
        <MqttComponent
          place="issue"
          issue={issueToModify}
          onShow={showMqttComponent}
          onClose={() => { setShowMqttComponent(false) }}
          userRole={userRole}
          units={unitsData}
          devices={devicesData}
        />
      )}
    </div>
  );
}

export default App;